export const environment = {
  production: true,
  appVersion: '0.0.1',
  host: 'https://app.makktina.com',
  apiVersion: '/api/v1',
  apiUrl: 'https://app.makktina.com/api/v1',
  secretKey: 'leKrAXPysuH7fYOsFIX16HYQSbtyIslr',
  webPlatform: '5A663988-47DC-47B3-94FE-0B47F5F824A8',
  socketUrl: 'https://app.makktina.com/ws/chatHub'
};
